<template>
  <div>
    <header>
        <div class="w-100">
            <div class="flex flex__align-items-center flex__justify-content-space-between content-container">
                <router-link class="hot__header " :to="{ name: 'vacancy-lend', query: query}"><img src="@/assets/img/Perekrestok_Logo_White.png" alt="" class="hot__header-logo" /></router-link>
                <div class="bg-logo-container"><img class="bg-logo" src="@/assets/img/Perekrestok_Sign_White.png" alt="" /></div>
            </div>
        </div>
    </header>
     <div class="w-100">
      <div class="content-container bg-white">
        <router-view :query="query"/>
        <hot-vacancies-common :query="query"/>
      </div>
    </div>
  </div>
</template>

<script>
import HotVacanciesCommon from '@/components/HotVacanciesCommon.vue'

export default {
  name: 'hot-vacancies-layout',
  components: {
    'hot-vacancies-common': HotVacanciesCommon
  },
  data () {
    return {
      query: {}
    }
  },
  created () {
    this.params = window
      .location
      .search
      .replace('?', '')
      .split('&')
      .reduce(
        function (p, e) {
          const a = e.split('=')
          p[decodeURIComponent(a[0])] = decodeURIComponent(a[1])
          return p
        },
        this.query
      )
  }
}
</script>
<style lang="scss">
  .block-text {
    font-weight: 600;
    font-size: 30px;
    color: #005A28;
  }
  .t-left {
    text-align: left !important;
  }

  .pt-0 {
     padding-top: 0px !important;
  }
  .pl-40 {
     padding-left: 40px !important;
  }
  .pb-20 {
     padding-bottom: 20px !important;
  }
  .mb-40 {
     margin-bottom: 40px !important;
  }
  .mb-50 {
     margin-bottom: 50px !important;
  }
  .gap-40 {
    gap: 40px
  }

  .w-200p {
      width: 200px;
  }

  .relative {
      position: relative;
  }

  .desc-block {
      max-width: 1060px;
      min-width: 300px;
      margin-left: auto;
      margin-right: auto;
      background: linear-gradient(#E9FBE9, #D1E0D1);
      border-radius: 3px;
      font-size: 18px;
      font-weight: 300;
      padding: 40px;
      margin-bottom: 20px;
  }

  .content-text {
    font-size: 18px;
    color: #005A28;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .header-text {
    font-weight: 600;
    font-size: 38px;
    color: #005A28;
    padding-top: 32px;
    padding-bottom: 32.41px;
  }

  .content-container {
      max-width: 1200px;
      min-width: 300px;
      margin-left: auto;
      margin-right: auto;
  }

  .bg-logo-container {
    width: 175px;
    height: 90px;
    position: relative;
  }

  .bg-white {
    background-color: #fff
  }

  .bg-logo {
    width: 100%;
    position: absolute;
    top: -40px;
    opacity: 0.1;
  }
  /* Базовые стили */

  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
  }

  body {
      min-height: 100vh;
      position: relative;
      color: var(--cl-black-soft);
      font-size: 16px;
      font-family: var(--pka);
      line-height: 1.2;
      background-color: var(--cl-bg-grey);
  }

  img {
      max-width: 100%;
      height: auto;
  }

  a {
      color: var(--cl-green);
      text-decoration: none;
      transition: all var(--time) ease;

      &:hover {
          color: var(--cl-dk-red);
          text-decoration: none;
      }
  }

  strong,
  b {
      font-weight: bold;
  }

  em,
  i {
      font-style: italic;
  }

  p {
      margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      margin: 0;
  }

  ul,
  ol {
      li {
          list-style: none outside;
      }
  }

  header {
      background-color: #005A28;
  }

  .has-overlay {
      overflow: hidden;
  }

  #app {
      display: flex;
      flex-direction: column;
      height: 100%;
  }

  .w-100 {
    width: 100%;
  }

  .flex {
      display: flex;

      &__align-items-center {
          align-items: center;
      }

      &__justify-content-center {
          justify-content: center;
      }

      &__justify-content-space-between {
          justify-content: space-between;
      }

      &__wrap {
          flex-wrap: wrap;
      }
      &__column {
        flex-direction: column;
      }
  }
  .hot__header-lnk {
    display: block;
    margin: 0 auto;
}

  .hot__header-logo {
      margin: 20px auto 20px;
      display: block;
      width: 300px;
      height: auto;
      cursor: pointer;
      transition: all .2s;
  }

  .hot__header-logo:hover {
      opacity: .7;
  }
  .hot-new__content{
    width: 80%;
    margin:0 auto;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 90px;
}

.hot-new__content h1{
    font-weight: 900;
    font-size: 40px;
    line-height: 56px;
    color: #181818;
}

.hot-new__content-header{
    text-align: center;
}

.hot {
    background: #f9f9f9;
    overflow-x: hidden;
    min-height: 100vh;
}

.hot__flex-jcc {
    justify-content: center;
}

.hot__none {
    display: none;
}

.hot__container {
    max-width: 1070px;
    margin: 0 auto;
}

.hot__btn {
    display: block;
    color: #fff;
    background: linear-gradient(#15753F, #005C29);
    border-radius: 3px;
    font-size: 22px;
    padding: 20px;
    min-width: 250px;
    max-width: 250px;
    text-align: center;
    box-sizing: border-box;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: all 0.2s;
}

.hot__btn:hover {
    opacity: 0.9;
}

.hot__slider {
    padding-bottom: 100px;
}

.hot__slider-sl {
    padding: 30px 0 200px;
    height: 525px;
    box-sizing: border-box;
}

.hot__slider-ttl {
    font-size: 80px;
    text-align: center;
}

.hot__slider-i {
    position: relative;
    width: 100%;
}

.hot__slider-sl .swiper-slide-active .hot__slider-i__image {
    margin-bottom: -100px;
}

.hot__slider-sl .swiper-slide.__other {
    transform: scale(0.9);
}

.hot__slider-sl .swiper-slide.__other .hot__slider-i__ttl {
    bottom: -20px;
}

.hot__slider-sl .swiper-slide.__other .hot__slider-i__image {
    margin-bottom: -50px;
}

.hot__slider-sl .swiper-slide-active .hot__slider-i__ttl {
    bottom: -80px;
    right: -50px;
}

.hot__slider-sl .swiper-slide {
    transition: all 0.3s;
}

.hot__slider-sl .swiper-slide-active {
    transform: scale(1.2);
}

.hot__slider-sl .swiper-slide-active:before,
.hot__slider-sl .swiper-slide-active:after,
.hot__slider-ic {
    position: absolute;
    display: block;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    background-color: #fff;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    box-shadow: 2px 4px 5px 0px rgba(76, 76, 76, 0.24);
}

.hot__slider-sl .swiper-slide-active:before {
    top: -60px;
    right: -30px;
}

.hot__slider-sl .swiper-slide-active:after {
    bottom: -90px;
    left: -10px;
}

.hot__slider-sl .swiper-slide.swiper-slide-active .hot__slider-ic-3 {
    bottom: 0;
}

.hot__slider-i__ttl {
    position: absolute;
    right: 0;
    bottom: 30px;
    z-index: 1;
    font-size: 14px;
    padding: 10px 20px;
    background: #fff;
    box-shadow: 0px 3px 20px rgba(29, 22, 23, 0.08);
    border-radius: 3px;
    max-width: 170px;
    word-break: inherit;
    white-space: break-spaces;
}

.hot__slider-i__image {
    width: 100%;
    height: auto;
    transition: all 0.3s;
}

.hot__sections {
    padding-bottom: 0px;
}

.hot__sections-cnt {
    gap: 40px;
}

.hot__sections-col {
    width: 255px;
    height: 319px;
}

.hot__sections-ttl {
    position: relative;
}

.hot__sections-ttl__img {
    margin-right: 5px;
    position: relative;
    z-index: 1;
}

.hot__sections-ttl__circle {
    display: block;
    position: absolute;
    background: #13592f;
    top: -15px;
    left: -15px;
    width: 100px;
    height: 100px;
    z-index: 0;
    border-radius: 100%;
}

.hot__sections-row {
    padding: 20px 25px;
    margin-bottom: 15px;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0px 5px 12px rgba(29, 22, 23, 0.08);
    box-sizing: border-box;
}

.hot__sections-col .hot__sections-row:last-child {
    margin-bottom: 0;
}

.hot__slider-ttl,
.hot__header,
.hot__sections-ttl,
.hot__sections-row__ttl {
    font-weight: 600;
}

.hot__sections-ttl {
    font-size: 18px;
}

.hot__b-b {
    padding: 20px 0;
    margin-top: 20px;
    border-top: 1px solid #eee;
}

.hot__b-t__social {
    margin-left: auto;
}

.hot__vacancy-head {
    padding: 30px 0 100px;
}

.hot__vacancy-head-l {
    width: calc(100% - 404px - 50px);
}

.hot__vacancy-head-r {
    width: 404px;
    position: relative;
}

.hot__vacancy-head-r.__xs {
    display: none;
}

.hot__vacancy-head-l {
    padding-right: 50px;
}

.hot__vacancy-head-ttl {
    font-weight: 600;
    font-size: 60px;
    margin-bottom: 30px;
}

.hot__vacancy-head-schedule__ttl {
    color: #13592f;
    font-size: 18px;
    margin-bottom: 5px;
}

.hot__vacancy-head-schedule__b {
    color: #13592f;
    font-weight: 600;
    font-size: 22px;
}

.hot__vacancy-head-meta {
    margin-bottom: 50px;
}

.hot__vacancy-head-meta-l {
    padding-right: 30px;
}

.hot__vacancy-head-img {
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.hot__vacancy-head-r:before,
.hot__vacancy-head-r:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 20px;
    border-radius: 100%;
    border: 1px solid #169046;
    transform: translate(-50%, -50%);
}

.hot__vacancy-head-r:before {
    width: 484px;
    height: 484px;
    opacity: 0.32;
}

.hot__vacancy-head-r:after {
    width: 630px;
    height: 630px;
    opacity: 0.12;
}

.hot__reasons {
    padding-bottom: 100px;
}

.hot__reasons-ttl {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 50px;
}

.hot__reasons-cnt {
    margin: 0 -15px;
}

.hot__reasons-col.fornew {
    width: 33%;
}

.hot__reasons-col {
    width: 25%;
    margin: 0 15px;
    font-size: 18px;
    text-align: center;
}

.hot__reasons-col__img {
    margin: 0 auto 30px;
    width: 124px;
    height: 124px;
    background: #fff;
    border-radius: 100%;
}
.hot__reasons-ttl.fornew{
    text-align: center;
}
.hot__reasons.fornew{
    margin-top: 120px;
    margin-bottom: 60px;
}
.hot__reasons-col__image.fornew{
    margin-bottom: 20px;
}

.hot__reasons-col__image {
    width: 180px;
}

.hot__reasons-col__ttl {
    font-weight: 600;
    margin-bottom: 10px;
}

.hot__reasons-col__txt {
    line-height: 1.5;
}

.hot__vacancy {
    padding-bottom: 80px;
}

.hot__vacancy-l,
.hot__vacancy-r {
    width: 100%;
}

.hot__vacancy-l {
    padding-right: 80px;
}

.hot__vacancy-l p {
    display: block;
}

.hot__vacancy-l p,
.hot__vacancy-l ul {
    margin-bottom: 20px;
    font-size: 18px;
}

.hot__vacancy-l ul {
    margin-left: 20px;
}

.hot__vacancy-l ul li {
    list-style: disc;
    line-height: 1.8;
}

.hot__vacancy-form {
    padding: 35px 55px;
    border-radius: 8px;
    background: #f0f0f0;
}

.hot__vacancy-form__lbl {
    display: block;
    margin-bottom: 10px;
}

.hot__vacancy-form .contact-form__radio-wrapper {
    margin-bottom: 20px;
}

.hot__vacancy-form__ttl {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 10px;
}

.hot__vacancy-form__txt {
    font-style: 18px;
    color: #999;
    margin-bottom: 30px;
}

.hot__vacancy-form__radio {
    label {
        &:first-child {
            margin-right: 20px;
        }
    }

    @media (--tablet-lg) {
        flex-wrap: wrap;

        label {
            width: 100%;

            &:first-child {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
    }
}

.hot__vacancy-form__city-selection__list {
    position: absolute;
    width: 100%;
    margin-top: 10px;
    z-index: 1;
}

.hot__vacancy-form__group-select{
    background-color: var(--cl-global-invert);
    padding: 0 15px;
    height: 60px;
    border: 1px solid var(--cl-lnk-bg-grey);
    border-radius: 10px;
}

.hot__vacancy-form__group-select .custom-select__label:before{
    content: none;
}

.hot__vacancy-form__group {
    position: relative;
    margin-bottom: 15px;

    &.__last {
        margin-bottom: 0;
    }
}

.hot__vacancy-form__group .error-message {
    margin: -15px 0 20px;
}

.hot .banner-outlook,
.hot .footer-main__menu-i,
.hot .socials,
.hot .footer-main__up {
    display: none;
}

@media (max-width: 1600px) {
    .hot__slider {
        padding-bottom: 50px;
    }
}

@media (max-width: 1400px) {
    .hot__slider {
        padding-bottom: 0;
    }
}

@media (max-width: 1200px) {
    .hot__slider-sl .swiper-slide.__other,
    .hot__slider-sl .swiper-slide-active {
        transform: scale(1);
    }

    .hot__slider-sl .swiper-slide-active .hot__slider-i__image {
        margin-bottom: 0;
    }

    .hot__slider-sl .swiper-slide-active .hot__slider-i__ttl {
        bottom: 20px;
        right: 0;
    }

    .hot__slider-ic-3 {
        bottom: auto;
        right: 0;
        top: 50px;
        right: 10px;
    }

    .hot__slider-sl .swiper-slide.__other .hot__slider-i__image {
        margin-bottom: 0;
    }
}

@media (max-width: 1099px) {
    .hot__container {
        max-width: 950px;
    }

    .hot__slider-ttl {
        font-size: 60px;
    }

    .hot__slider-sl .swiper-slide-active:before,
    .hot__slider-sl .swiper-slide-active:after {
        display: none;
    }
}

@media (max-width: 992px) {
    .header-text {
        font-size: 35px;
        margin-left: 25px;
        margin-right: 25px;
    }
    .hot__container {
        max-width: 720px;
    }

    .hot__slider {
        padding-bottom: 30px;
    }

    .hot__slider-sl {
        padding: 30px 0 50px;
        height: auto;
    }

    .hot__slider-ttl {
        font-size: 40px;
    }

    .hot__slider-ttl__fire {
        width: 60px;
        height: 60px;
        margin-left: -10px;
        margin-right: -10px;
    }

    .hot__sections-row__ttl {
        font-size: 14px;
    }

    .hot__slider-sl .swiper-slide-active:before,
    .hot__slider-sl .swiper-slide-active:after,
    .hot__slider-ic {
        display: block;
        width: 35px;
        height: 35px;
        background-size: 60%;
    }

    .hot__slider-sl .swiper-slide-active:before {
        top: 0;
        right: -40px;
    }

    .hot__slider-sl .swiper-slide-active:after {
        bottom: -20px;
    }

    .hot__slider-sl .swiper-slide-prev .hot__slider-i:before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background-color: #fff;
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        box-shadow: 2px 4px 5px 0px rgba(76, 76, 76, 0.24);
    }

    .hot__vacancy-head-r {
        width: 250px;
    }

    .hot__vacancy-head-r:before,
    .hot__vacancy-head-r:after {
        margin-top: 14px;
    }

    .hot__vacancy-head-r:before {
        width: 284px;
        height: 284px;
    }

    .hot__vacancy-head-r:after {
        width: 400px;
        height: 400px;
    }

    .hot__vacancy-head-l {
        width: calc(100% - 284px - 30px);
        padding-right: 30px;
    }

    .hot__vacancy-head-ttl {
        font-size: 32px;
    }

    .hot__btn {
        padding: 15px;
        min-width: 220px;
        max-width: 220px;
    }

    .hot__reasons-col,
    .hot__vacancy-head-schedule__ttl,
    .hot__vacancy-head-schedule__b {
        font-size: 16px;
    }

    .hot__vacancy-head-meta {
        margin-bottom: 30px;
    }

    .hot__vacancy-l {
        padding-right: 40px;
    }

    .hot__vacancy-l p,
    .hot__vacancy-l ul {
        margin-bottom: 15px;
        font-size: 16px;
    }

    .hot__vacancy-form {
        padding: 25px 25px;
    }

    .hot__vacancy-form__ttl {
        font-size: 26px;
    }

    .hot .footer-main__menu {
        display: none;
    }

    .hot .footer-main__logo {
        width: 242px;
    }
}

@media (max-width: 767px) {

    .hot__about-desc {
        padding: 166px 25px 25px 25px !important;
    }

    .hot__sections {
      padding: 0px;
    }

    .hot__reasons,
    .hot__vacancy,
    .hot__slider-ttl {
        padding: 0 15px;
    }

    .hot__header-logo {
        margin: 10px auto;
        width: 132px;
    }

    .hot__slider {
        padding-bottom: 20px;
    }

    .hot__slider-ttl {
        font-size: 34px;
    }

    .hot__slider-sl .swiper-slide {
        width: 200px;
    }

    .hot__slider-sl .swiper-slide-active:after {
        left: 20px;
    }

    .hot__slider-sl .hot__slider-i__ttl {
        bottom: 0;
        right: 0;
        left: 0;
        max-width: 100%;
        text-align: center;
    }

    .hot__slider-sl .swiper-slide-prev .hot__slider-i:left {
        top: 0;
        right: -40px;
    }

    .hot__sections-cnt {
        flex-direction: column;
    }

    .hot__sections-col:last-child {
        margin-top: 40px;
    }

    .hot__sections-ttl__circle {
        width: 75px;
        height: 75px;
        top: -10px;
    }

    .hot__sections-ttl__img {
        margin-right: 10px;
    }

    .hot__sections {
        padding-bottom: 0px;
    }

    .hot__f .container {
        padding-left: 0;
        padding-right: 30px;
    }

    .hot__vacancy,
    .hot__reasons {
        padding-bottom: 50px;
    }

    .hot__reasons-ttl {
        font-size: 26px;
        margin-bottom: 30px;
        text-align: center;
    }

    .hot__vacancy-head-cnt,
    .hot__vacancy-head-meta,
    .hot__vacancy-cnt,
    .hot__reasons-cnt {
        flex-wrap: wrap;
    }

    .hot__reasons-col {
        width: 100%;
        margin-bottom: 30px;
    }

    .hot__reasons-col__img {
        margin-bottom: 20px;
    }

    .hot__vacancy-head-ttl,
    .hot__vacancy-head-meta {
        text-align: center;
    }

    .hot__vacancy-head {
        padding: 15px 15px 50px;
    }

    .hot__vacancy-head-l,
    .hot__vacancy-head-r {
        width: 100%;
    }

    .hot__vacancy-head-l {
        padding-right: 0;
    }

    .hot__vacancy-head-meta-r,
    .hot__vacancy-head-meta-l {
        width: 100%;
    }

    .hot__vacancy-head-meta-l {
        padding-right: 0;
        margin-bottom: 15px;
    }

    .hot__vacancy-head-meta-r .hot__btn {
        margin: 0 auto;
    }

    .hot__vacancy-head-r.__md {
        display: none;
    }

    .hot__vacancy-head-r.__xs {
        display: block;
        margin-bottom: 30px;
    }

    .hot__slider-sl .swiper-slide-active .hot__slider-i__ttl {
        bottom: 0;
    }

    .hot__slider-sl .swiper-slide-active:after {
        bottom: -30px;
    }

    .hot__slider-sl .swiper-slide-prev .hot__slider-i:before {
        right: -60px;
    }

    .hot .footer-main__logo {
        width: 300px;
        margin: 0 auto;
    }

    .hot .footer-main__info {
        margin-bottom: 20px;
    }
}
.browse-happy {
    background: var(--cl-global-invert);
    padding: 20px 0;
    text-align: center;

    strong {
        color: var(--cl-green);
    }

    &__notice {
        font-size: 20px;
    }

    &__security {
        font-size: 12px;
    }

    &__update {
        margin-bottom: calc(var(--gs) * 5);
        font-size: 14px;
    }

    &__recommend {
        font-size: 12px;
    }
}

.wrap-main {
    position: relative;
    min-width: var(--mobile-sm-size-min);
    margin: 0;
    padding-top: 122px;
    flex: 1 0 auto;

    @media (--tablet) {
        padding-top: 96px;
    }
}

.container {
    position: relative;
    min-width: var(--mobile-sm-size-min);
    max-width: 1150px;
    margin: 0 auto;
    padding: 0 15px;

    @media (--desktop) {
        max-width: calc(var(--tablet-lg-size-min) + 30px);
    }

    @media (--tablet-lg) {
        max-width: calc(var(--tablet-size-min) + 30px);
    }

    @media (--tablet) {
        max-width: calc(var(--mobile-lg-size-min) + 30px);
    }
}
  @media (max-width: 1279px) {
    .container {
      max-width: calc(var(--tablet-lg-size-min) + 30px);
    }
  }
.hot-new__content{
    width: 80%;
    margin:0 auto;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 90px;
}

.hot-new__content h1{
    font-weight: 900;
    font-size: 40px;
    line-height: 56px;
    color: #181818;
}

.hot-new__content-header{
    text-align: center;
}

.hot {
    background: #f9f9f9;
    overflow-x: hidden;
    min-height: 100vh;
}

.hot__flex-jcc {
    justify-content: center;
}

.hot__none {
    display: none;
}

.hot__container {
    max-width: 1070px;
    margin: 0 auto;
}

.hot__header-lnk {
    display: block;
    margin: 0 auto;
}

.hot__slider {
    padding-bottom: 100px;
}

.hot__slider-sl {
    padding: 30px 0 200px;
    height: 525px;
    box-sizing: border-box;
}

.hot__slider-ttl {
    font-size: 80px;
    text-align: center;
}

.hot__slider-i {
    position: relative;
    width: 100%;
}

.hot__slider-sl .swiper-slide-active .hot__slider-i__image {
    margin-bottom: -100px;
}

.hot__slider-sl .swiper-slide.__other {
    transform: scale(0.9);
}

.hot__slider-sl .swiper-slide.__other .hot__slider-i__ttl {
    bottom: -20px;
}

.hot__slider-sl .swiper-slide.__other .hot__slider-i__image {
    margin-bottom: -50px;
}

.hot__slider-sl .swiper-slide-active .hot__slider-i__ttl {
    bottom: -80px;
    right: -50px;
}

.hot__slider-sl .swiper-slide {
    transition: all 0.3s;
}

.hot__slider-sl .swiper-slide-active {
    transform: scale(1.2);
}

.hot__slider-sl .swiper-slide-active:before,
.hot__slider-sl .swiper-slide-active:after,
.hot__slider-ic {
    position: absolute;
    display: block;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    background-color: #fff;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    box-shadow: 2px 4px 5px 0px rgba(76, 76, 76, 0.24);
}

.hot__slider-sl .swiper-slide-active:before {
    top: -60px;
    right: -30px;
}

.hot__slider-sl .swiper-slide-active:after {
    bottom: -90px;
    left: -10px;
}

.hot__slider-sl .swiper-slide.swiper-slide-active .hot__slider-ic-3 {
    bottom: 0;
}

.hot__slider-i__image {
    width: 100%;
    height: auto;
    transition: all 0.3s;
}

.hot__sections {
    padding-bottom: 0px;
}

.hot__sections-ttl__circle {
    display: block;
    position: absolute;
    background: #13592f;
    top: -15px;
    left: -15px;
    width: 100px;
    height: 100px;
    z-index: 0;
    border-radius: 100%;
}

.hot__sections-col .hot__sections-row:last-child {
    margin-bottom: 0;
}

.hot__slider-ttl,
.hot__header,
.hot__sections-ttl,
.hot__sections-row__ttl {
    font-weight: 600;
}

.hot__sections-row__btn {
    background: linear-gradient(#15753F, #005C29);
    border-radius: 3px;
    color: #fff;
    padding: 8px 15px;
    transition: all 0.2s;
    cursor: pointer;
    text-align: center;
}

.hot__sections-row__btn:hover {
    color: #fff;
    opacity: 0.8;
}

.hot__b-b {
    padding: 20px 0;
    margin-top: 20px;
    border-top: 1px solid #eee;
}

.hot__b-t__social {
    margin-left: auto;
}

.hot__vacancy-head {
    padding: 30px 0 100px;
}

.hot__vacancy-head-l {
    width: calc(100% - 404px - 50px);
}

.hot__vacancy-head-r {
    width: 404px;
    position: relative;
}

.hot__vacancy-head-r.__xs {
    display: none;
}

.hot__vacancy-head-l {
    padding-right: 50px;
}

.hot__vacancy-head-ttl {
    font-weight: 600;
    font-size: 60px;
    margin-bottom: 30px;
}

.hot__vacancy-head-schedule__ttl {
    color: #13592f;
    font-size: 18px;
    margin-bottom: 5px;
}

.hot__vacancy-head-schedule__b {
    font-weight: 600;
    font-size: 22px;
}

.hot__vacancy-head-meta {
    margin-bottom: 50px;
}

.hot__vacancy-head-meta-l {
    padding-right: 30px;
}

.hot__vacancy-head-img {
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.hot__vacancy-head-r:before,
.hot__vacancy-head-r:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 20px;
    border-radius: 100%;
    border: 1px solid #169046;
    transform: translate(-50%, -50%);
}

.hot__vacancy-head-r:before {
    width: 484px;
    height: 484px;
    opacity: 0.32;
}

.hot__vacancy-head-r:after {
    width: 630px;
    height: 630px;
    opacity: 0.12;
}

.hot__reasons {
    padding-bottom: 100px;
}

.hot__reasons-ttl {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 50px;
}

.hot__reasons-cnt {
    margin: 0 -15px;
}

.hot__reasons-col.fornew {
    width: 33%;
}

.hot__reasons-col {
    width: 25%;
    margin: 0 15px;
    font-size: 18px;
    text-align: center;
}

.hot__reasons-col__img {
    margin: 0 auto 30px;
    width: 124px;
    height: 124px;
    background: #fff;
    border-radius: 100%;
}
.hot__reasons-ttl.fornew{
    text-align: center;
}
.hot__reasons.fornew{
    margin-top: 120px;
    margin-bottom: 60px;
}

.hot__reasons-col__ttl {
    font-weight: 600;
    margin-bottom: 10px;
}

.hot__reasons-col__txt {
    line-height: 1.5;
}

.hot__vacancy {
    padding-bottom: 80px;
}

.hot__vacancy-l,
.hot__vacancy-r {
    width: 100%;
}

.hot__vacancy-l {
    padding-right: 80px;
}

.hot__vacancy-l p {
    display: block;
}

.hot__vacancy-l p,
.hot__vacancy-l ul {
    margin-bottom: 20px;
    font-size: 18px;
}

.hot__vacancy-l ul {
    margin-left: 20px;
}

.hot__vacancy-l ul li {
    list-style: disc;
    line-height: 1.8;
}

.hot__vacancy-form {
    padding: 35px 55px;
    border-radius: 8px;
    background: #f0f0f0;
}

.hot__vacancy-form__lbl {
    display: block;
    margin-bottom: 10px;
}

.hot__vacancy-form .contact-form__radio-wrapper {
    margin-bottom: 20px;
}

.hot__vacancy-form__ttl {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 10px;
}

.hot__vacancy-form__txt {
    font-style: 18px;
    color: #999;
    margin-bottom: 30px;
}

.hot__vacancy-form__radio {
    label {
        &:first-child {
            margin-right: 20px;
        }
    }

    @media (--tablet-lg) {
        flex-wrap: wrap;

        label {
            width: 100%;

            &:first-child {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
    }
}

.hot__vacancy-form__city-selection__list {
    position: absolute;
    width: 100%;
    margin-top: 10px;
    z-index: 1;
}

.hot__vacancy-form__group-select{
    background-color: var(--cl-global-invert);
    padding: 0 15px;
    height: 60px;
    border: 1px solid var(--cl-lnk-bg-grey);
    border-radius: 10px;
}

.hot__vacancy-form__group-select .custom-select__label:before{
    content: none;
}

.hot__vacancy-form__group {
    position: relative;
    margin-bottom: 15px;

    &.__last {
        margin-bottom: 0;
    }
}

.hot__vacancy-form__group .error-message {
    margin: -15px 0 20px;
}

.hot .banner-outlook,
.hot .footer-main__menu-i,
.hot .socials,
.hot .footer-main__up {
    display: none;
}

@media (max-width: 1600px) {
    .hot__slider {
        padding-bottom: 50px;
    }
}

@media (max-width: 1400px) {
    .hot__slider {
        padding-bottom: 0;
    }
}

@media (max-width: 1200px) {
    .hot__slider-sl .swiper-slide.__other,
    .hot__slider-sl .swiper-slide-active {
        transform: scale(1);
    }

    .hot__slider-sl .swiper-slide-active .hot__slider-i__image {
        margin-bottom: 0;
    }

    .hot__slider-sl .swiper-slide-active .hot__slider-i__ttl {
        bottom: 20px;
        right: 0;
    }

    .hot__slider-ic-3 {
        bottom: auto;
        right: 0;
        top: 50px;
        right: 10px;
    }

    .hot__slider-sl .swiper-slide.__other .hot__slider-i__image {
        margin-bottom: 0;
    }
}

@media (max-width: 1099px) {
    .hot__container {
        max-width: 950px;
    }

    .hot__slider-ttl {
        font-size: 60px;
    }

    .hot__slider-sl .swiper-slide-active:before,
    .hot__slider-sl .swiper-slide-active:after {
        display: none;
    }
}

@media (max-width: 992px) {
    .hot__container {
        max-width: 720px;
    }

    .hot__slider {
        padding-bottom: 30px;
    }

    .hot__slider-sl {
        padding: 30px 0 50px;
        height: auto;
    }

    .hot__slider-ttl {
        font-size: 40px;
    }

    .hot__slider-ttl__fire {
        width: 60px;
        height: 60px;
        margin-left: -10px;
        margin-right: -10px;
    }

    .hot__sections-row__ttl {
        font-size: 14px;
    }

    .hot__slider-sl .swiper-slide-active:before,
    .hot__slider-sl .swiper-slide-active:after,
    .hot__slider-ic {
        display: block;
        width: 35px;
        height: 35px;
        background-size: 60%;
    }

    .hot__slider-sl .swiper-slide-active:before {
        top: 0;
        right: -40px;
    }

    .hot__slider-sl .swiper-slide-active:after {
        bottom: -20px;
    }

    .hot__slider-sl .swiper-slide-prev .hot__slider-i:before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background-color: #fff;
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        box-shadow: 2px 4px 5px 0px rgba(76, 76, 76, 0.24);
    }

    .hot__vacancy-head-r {
        width: 250px;
    }

    .hot__vacancy-head-r:before,
    .hot__vacancy-head-r:after {
        margin-top: 14px;
    }

    .hot__vacancy-head-r:before {
        width: 284px;
        height: 284px;
    }

    .hot__vacancy-head-r:after {
        width: 400px;
        height: 400px;
    }

    .hot__vacancy-head-l {
        width: calc(100% - 284px - 30px);
        padding-right: 30px;
    }

    .hot__vacancy-head-ttl {
        font-size: 32px;
    }

    .hot__reasons-col,
    .hot__vacancy-head-schedule__ttl,
    .hot__vacancy-head-schedule__b {
        font-size: 16px;
    }

    .hot__vacancy-head-meta {
        margin-bottom: 30px;
    }

    .hot__vacancy-l {
        padding-right: 40px;
    }

    .hot__vacancy-l p,
    .hot__vacancy-l ul {
        margin-bottom: 15px;
        font-size: 16px;
    }

    .hot__vacancy-form {
        padding: 25px 25px;
    }

    .hot__vacancy-form__ttl {
        font-size: 26px;
    }

    .hot .footer-main__menu {
        display: none;
    }
}

@media (max-width: 767px) {

    .hot__about-desc {
        padding: 166px 25px 25px 25px !important;
    }

    .hot__sections {
        padding: 0px;
    }
    .hot__reasons,
    .hot__vacancy,
    .hot__slider-ttl {
        padding: 0 15px;
    }

    .hot__header-logo {
        margin: 10px auto;
        width: 200px;
    }

    .hot__slider {
        padding-bottom: 20px;
    }

    .hot__slider-ttl {
        font-size: 34px;
    }

    .hot__slider-sl .swiper-slide {
        width: 200px;
    }

    .hot__slider-sl .swiper-slide-active:after {
        left: 20px;
    }

    .hot__slider-sl .hot__slider-i__ttl {
        bottom: 0;
        right: 0;
        left: 0;
        max-width: 100%;
        text-align: center;
    }

    .hot__slider-sl .swiper-slide-prev .hot__slider-i:left {
        top: 0;
        right: -40px;
    }

    .hot__sections-col:last-child {
        margin-top: 40px;
    }

    .hot__sections-ttl__circle {
        width: 75px;
        height: 75px;
        top: -10px;
    }

    .hot__sections-row__btn {
        padding: 8px 10px;
    }

    .hot__sections {
        padding-bottom: 0px;
    }

    .hot__f .container {
        padding-left: 0;
        padding-right: 30px;
    }

    .hot__vacancy,
    .hot__reasons {
        padding-bottom: 50px;
    }

    .hot__reasons-ttl {
        font-size: 26px;
        margin-bottom: 30px;
        text-align: center;
    }

    .hot__vacancy-head-cnt,
    .hot__vacancy-head-meta,
    .hot__vacancy-cnt,
    .hot__reasons-cnt {
        flex-wrap: wrap;
    }

    .hot__reasons-col {
        width: 100%;
        margin-bottom: 30px;
    }

    .hot__reasons-col__img {
        margin-bottom: 20px;
    }

    .hot__vacancy-head-ttl,
    .hot__vacancy-head-meta {
        text-align: center;
    }

    .hot__vacancy-head {
        padding: 15px 15px 50px;
    }

    .hot__vacancy-head-l,
    .hot__vacancy-head-r {
        width: 100%;
    }

    .hot__vacancy-head-l {
        padding-right: 0;
    }

    .hot__vacancy-head-meta-r,
    .hot__vacancy-head-meta-l {
        width: 100%;
    }

    .hot__vacancy-head-meta-l {
        padding-right: 0;
        margin-bottom: 15px;
    }

    .hot__vacancy-head-r.__md {
        display: none;
    }

    .hot__vacancy-head-r.__xs {
        display: block;
        margin-bottom: 30px;
    }

    .hot__slider-sl .swiper-slide-active .hot__slider-i__ttl {
        bottom: 0;
    }

    .hot__slider-sl .swiper-slide-active:after {
        bottom: -30px;
    }

    .hot__slider-sl .swiper-slide-prev .hot__slider-i:before {
        right: -60px;
    }

    .hot .footer-main__info {
        margin-bottom: 20px;
    }
}

</style>
