export const vacancies = {
  delivery: {
    type: 'delivery',
    title: 'Зарабатывай в&nbsp;доставке',
    img: 'dostavka5_1',
    word: 'локацию',
    schedule: 'График – очень&nbsp;удобный',
    text: `Мы с удовольствием подберем идеальную вакансию и удобную локацию.<br>
    Скорее заполняй анкету – и&nbsp;мы&nbsp;перезвоним!<br><br>
    <b>В нашей доставке:</b><br><br>
    Любимым клиентам быстро привозим заказы на личном автомобиле или велосипеде. Это делают наши скоростные и&nbsp;маневренные&nbsp;<b>суперкурьеры</b>
    `
  },
  stock: {
    type: 'stock',
    title: 'Зарабатывай в распределительном центре',
    img: 'rc_2',
    word: 'распределительный центр',
    schedule: 'График – очень&nbsp;удобный',
    text: `Мы с удовольствием подберем подходящую вакансию и удобный распределительный центр.<br>
    Скорее заполняй анкету - и&nbsp;мы&nbsp;перезвоним!<br><br>
    <b>В наших распределительных центрах:</b><br><br>
    Перемещаем товары с помощью крутейшей современной погрузочной техники, ей управляют <b>водители электроштабелеров</b> (кстати, у нас есть и леди-водители), а бережная сборка заказов – на ответственных <b>кладовщиках-комплектовщиках</b><br><br>
    Принимают товары и распределяют их по супермаркетам – наши <b>приемосдатчики и операторы отгрузки</b>
    `
  },
  market: {
    type: 'market',
    title: 'Зарабатывай в&nbsp;супермаркете',
    img: 'magazin3',
    word: 'супермаркет',
    schedule: 'График – очень&nbsp;удобный',
    text: `Мы с удовольствием подберем подходящую вакансию, удобный супермаркет и необходимый формат работы.<br>
    Скорее заполняй анкету – мы перезвоним!<br><br>
    <b>В наших супермаркетах:</b><br><br>
    Создаём атмосферу гостеприимства и доверия под руководством <b>директора супермаркета и лидеров торгового зала.</b><br>
Следим за соответствием единого стандарта клиентского опыта. В этом помогают <b>мерчандайзеры.</b><br>
Консультируем покупателей и предлагаем пробовать новое. Гуру сервиса - наши <b>продавцы фруктов и овощей, морепродуктов, гастрономии.</b><br>
Накормим вкусно всех. Магию вкуса создают <b>пекари, кондитеры, пиццамейкеры, повара коптильни, ВОК, салатов и суши.</b> А <b>бариста</b> готовят для наших клиентов самый ароматный кофе. <br>
Помогаем подобрать алкоголь. В этом профи – наши <b>кависты.</b><br>
Наши <b>ассистенты и кассиры</b> – помогают на кассах самообслуживания и проводят оплату покупок.<br>
А ещё мы привезем заказ клиенту. Наши сверхскоростные <b>сборщики</b> в супермаркете заботливо соберут любой заказ.<br>
    `
  }
}
