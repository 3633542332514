<template>
    <div>
        <div class="hot__container">
            <div class="desc-block">
                Работа в Перекрёстке – это партнёрство с прозрачными условиями труда в технологичном ритейле.<br><br>
                У каждого индивидуальный график и очень удобный район.<br><br>
                Зарабатывай и подрабатывай с Перекрёстком!<br><br>
            </div>
        </div>
        <div class="hot__achievs">
            <div class="hot__container">
                <div class="hot__achievs-cnt flex">
                    <div class="hot__achievs-col" v-for="(k, i) in achievs" :key="i">
                        <img :src="k.img" alt="" class="hot__achievs-col__image" />

                        <div class="hot__achievs-col__txt">{{ k.text }}</div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer-main">
            <div class="hot__container">
                <div class="footer-main__top flex  flex__align-items-center flex__justify-content-center">
                    <router-link to="/" class="footer-main__logo">
                        <img src="@/assets/img/tcx_logo.png" alt="Пятёрочка" class="footer-main__logo-img" />
                    </router-link>
                    <div class="footer-main__menu __first"></div>
                    <div class="footer-main__menu"></div>
                    <div class="footer-main__info">
                        <span class="footer-main__up js-up-btn" @click="scrollTop"></span>
                        <a href="/pdf/politics-personal-data.docx" class="footer-main__policy" target="_blank"
                            >Политика в отношении обработки персональных данных</a
                        >
                    </div>
                </div>

                <div class="footer-main__bottom">
                    © 2012–{{ year }} АО «Торговый дом «ПЕРЕКРЁСТОК» . Любое использование контента без письменного разрешения АО «Торговый дом «ПЕРЕКРЁСТОК»
                    запрещено.
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

export default {
  name: 'hot-vacancies-common',
  data () {
    return {
      achievs: [
        {
          text: 'Официальное трудоустройство',
          img: require('@/assets/img/perek_HR_icon_4.png')
        },
        {
          text: 'Стабильность',
          img: require('@/assets/img/perek_HR_icon_5.png')
        },
        {
          text: 'Выплаты до 6 раз в месяц',
          img: require('@/assets/img/perek_HR_icon_6.png')
        },
        {
          text: 'Гибкий график',
          img: require('@/assets/img/perek_HR_icon_7.png')
        },
        {
          text: 'Скидки для сотрудников',
          img: require('@/assets/img/perek_HR_icon_8.png')
        }
      ]
    }
  },
  computed: {
    word () {
      if (this.$route.name === 'vacancy-lend-detail') {
        if (this.$route.params.type === 'stock') return 'очень удобный распределительный центр'
        if (this.$route.params.type === 'market') return 'очень удобный супермаркет'
        if (this.$route.params.type === 'delivery') return 'очень удобный район'
        return 'очень удобная локация'
      }
      return 'очень удобная локация'
    },
    year () {
      return new Date().getFullYear()
    }
  },
  methods: {
    scrollTop () {
      window.scroll({ top: 0, behavior: 'smooth' })
    },
    upBtnListener () {
      const upBtn = document.querySelector('.js-up-btn')

      if (upBtn) {
        window.addEventListener(
          'scroll',
          function () {
            clearTimeout(this.upBtnTimeout)

            this.upBtnTimeout = setTimeout(function () {
              const offsetTop = window.pageYOffset
              const upBtnClassList = Array.from(upBtn.classList)

              if (offsetTop > 600) {
                if (!upBtnClassList.includes('__active')) {
                  upBtn.classList.add('__active')
                }
              } else {
                if (upBtnClassList.includes('__active')) {
                  upBtn.classList.remove('__active')
                }
              }
            }, 200)
          },
          false
        )
      }
    }
  },
  mounted () {
    this.upBtnListener()
  },
  updated () {
    this.upBtnListener()
  }
}
</script>

<style scoped lang="scss">
.hot__achievs-col__txt {
        font-size: 15px;
        font-weight: 600;
        color: #005A28;
    }
    .hot__about,
.hot__achievs {
    padding-bottom: 80px;
}

.hot__achievs-ttl {
    font-weight: 600;
    font-size: 32px;
    text-align: center;
    margin-bottom: 50px;
}

.hot__achievs-cnt {
    margin: 0 -25px;
    justify-content: space-between;
}

.hot__achievs-col {
    width: 20%;
    padding: 0 25px;
    text-align: center;
}

.hot__achievs-col__img {
    position: relative;
    margin: 0 auto;
    width: 124px;
    height: 124px;
    background: #13592f;
    border-radius: 100%;
    margin-bottom: 15px;
}

.hot__achievs-col__image {
    margin-top: 20px;
}

.hot__about-cnt {
    position: relative;
}

.hot__about-img {
    position: absolute;
    top: -50px;
    left: 0;
    z-index: 1;
}

.hot__about-desc {
    margin-left: auto;
    width: 858px;
    background: linear-gradient(#E9FBE9, #D1E0D1);
    background-size: contain;
    border-radius: 3px;
    padding: 55px 45px 55px 220px;
    font-size: 18px;
    line-height: 1.8;
    box-sizing: border-box;
}

.hot__about-desc p {
    display: block;
    margin-bottom: 30px;
}

.hot__about-desc p:last-child {
    margin-bottom: 0;
}

.hot__about-desc ul {
    list-style: none;
}

.hot__about-desc ul li {
    padding-left: 25px;
    position: relative;
}

.hot__about-desc ul li:before {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    width: 8px;
    height: 8px;
    background-color: #169046;
    border-radius: 100%;
}

@media (max-width: 1099px) {
    .hot__about-desc {
        border-top-left-radius: 100px;
        padding-left: 360px;
    }
}

@media (max-width: 992px) {
    .hot__achievs-col__img {
        width: 104px;
        height: 104px;
    }

    .hot__achievs-col__image {
        width: 200px;
    }

    .hot__about-img {
        top: -200px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .hot__about-img {
        width: 300px;
        top: -200px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .hot__about-desc {
        border-top-left-radius: 3px;
        width: 100%;
        height: auto;
        font-size: 14px;
        padding: 180px 30px 30px 30px;
    }

    .hot__about-desc p {
        margin-bottom: 15px;
    }

    .hot__about-desc ul li:before {
        top: 8px;
    }

    .hot__achievs {
        padding-bottom: 250px;
    }
}

@media (max-width: 767px) {
    .hot__about {
        padding-bottom: 40px;
    }

    .hot__achievs {
        padding-bottom: 180px;
    }

    .hot__achievs-ttl {
        font-size: 22px;
    }

    .hot__achievs-col {
        width: 100%;
        font-weight: 600;
        margin-bottom: 30px;
    }

    .hot__about-img {
        width: 250px;
        top: -140px;
    }

    .hot__about-desc {
        border-top-left-radius: 3px;
        background-size: cover;
        line-height: 1.4;
        font-size: 13px;
        overflow: hidden;
        padding: 100px 25px 25px 25px;
    }

    .hot__achievs-cnt {
        flex-wrap: wrap;
    }
}
.footer-main {
    flex: 0 0 auto;

    &__top {
        padding-bottom: 40px;
        @media (--tablet-lg) {
            flex-wrap: wrap;
        }
    }

    &__bottom {
        text-align: center;
        padding: 22px;
        border-radius: 42px 42px 0 0;
        background-color: var(--cl-lnk-bg-grey);
        font-size: 14px;
        color: var(--cl-grey);
    }

    &__menu {
        margin-right: 20px;
        width: 220px;

        @media (--desktop) {
            width: 200px;
        }

        &.__first {
            margin-left: auto;
        }

        &-i {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-lnk {
            color: var(--cl-global);

            &:hover {
                color: var(--cl-green);
            }
        }
    }

    &__info {
        position: relative;
        width: 260px;
        text-align: right;
    }

    &__policy {
        color: var(--cl-grey);

        &:hover {
            color: var(--cl-black);
        }

        &,
        &:hover {
            text-decoration: underline;
        }
    }

    &__socials {
        justify-content: flex-end;
        margin-bottom: 50px;
    }

    &__up {
        display: none;
        position: fixed;
        z-index: 9;
        right: 20px;
        bottom: 20px;
        width: 74px;
        height: 74px;
        border-radius: 100%;
        background: url("@/assets/img/svg/arrow-login-up-white.svg") no-repeat 50% 50% var(--cl-green);
        background-size: 30px 30px;
        cursor: pointer;

        &.__active {
            display: block;
        }
    }
}
.footer-main {
  &__logo {
    &-img {
      width: 400px;
    }
  }
}

@media (--desktop) {
    .footer-main {
        &__socials {
            justify-content: flex-start;
        }

        &__up {
            display: block;
            position: absolute;
            top: -10px;
            right: 0;
            bottom: auto;
        }
    }
}

@media (max-width: 1023px) {
    .footer-main {
        &__top {
            flex-wrap: wrap;
        }

        &__logo {
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
            &-img {
                width: 400px;
            }
        }

        &__menu {
            &.__first {
                margin-left: 0;
            }
        }

        &__info {
            width: 100%;
            flex-wrap: wrap;
            text-align: center;
        }
    }
}

</style>
