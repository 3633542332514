import Vue from 'vue'
import VueRouter from 'vue-router'
import HotVacanciesLayout from '@/views/HotVacanciesLayout.vue'
import HotVacancies from '@/views/HotVacancies.vue'
import HotVacancy from '@/views/HotVacancy.vue'
import SobesPage from '@/views/SobesPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HotVacanciesLayout,
    children: [
      {
        path: '/',
        name: 'vacancy-lend',
        component: HotVacancies
      },
      {
        path: '/vacancy/:type',
        name: 'vacancy-lend-detail',
        component: HotVacancy,
        props: true
      }
    ]
  },
  {
    path: '/sobesedovanie',
    component: SobesPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
